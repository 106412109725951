.container {
  display: flex;
  flex-direction: column;
  min-width: 80vh;
}
.heading {
  background-color: #2c3539;
  color: white;
  align-self: center;
  padding: 0px 20px;
  border-radius: 10px;
}
.heading h2 {
  margin: 5px;
}
.input {
  margin: 20px;
  padding: 5px;
  border-radius: 5px;
}
.description:hover {
  white-space: normal;
}
.posts_list {
  margin: 20px;
}
table {
  width: 90%;
  border-collapse: collapse;
  margin-top: 20px;
}
th,
td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  vertical-align: top;
}
th {
  background-color: #f2f2f2;
}
.description {
  position: relative;
  display: inline-block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.full_description {
  position: absolute;
  top: 0%;
  left: 0%;
  display: none;
  width: 100%;
  height: 200%;
  overflow: visible;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 999999;
}
.description:hover .full_description {
  display: block;
}
.delete_button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.reason {
  font-size: 100px;
  max-width: 200px;
  background-color: red;
  color: pink;
}
.back_button {
  background-color: #2c3539;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 100px;
  font-size: 20px;
}
.back_button:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
}
