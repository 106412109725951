.form {
  max-width: 600px;
  margin: 10px auto;
  padding: 20px;
  border: 1px solid black;
  border-radius: 4px;
}

.label {
  display: block;
  margin-bottom: 20px;
}

.input,
.textarea,
.select {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.textarea {
  height: 100px;
}

.button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #2c3539;
  color: white;
  border: none;
  border-radius: 4px;
}

.button:hover {
  background-color: #3d4f57;
}
