.loader {
  margin: 50px auto;
  border: 6px solid black;
  border-radius: 50%;
  border-bottom-color: #f5f5f5;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
