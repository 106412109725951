.container {
  height: 30px;
  background-color: #222;
  display: flex;
}

.item {
  flex: 1;
  position: relative;
  display: flex;
}

.item:last-child {
  flex: 2;
  padding: 5px;
  justify-content: space-between;
}

.card {
  flex: 1;
  padding: 0 20px;
}

.title {
  color: #b7903c;
}

.text {
  color: lightgray;
}

.motto {
  color: rgb(211, 211, 211);
  margin: 5px;
  font-size: 12px;
  font-weight: 400;
}
