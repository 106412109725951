.container {
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
  min-height: 100vh;
}
.title {
  align-self: center;
  background-color: #2c3539;
  color: white;
  padding: 10px 20px;
  margin: 20px 0;
  border-radius: 10px;
}
.admin_dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.admin_dashboard__button {
  margin: 10px;
}

.admin_dashboard__button button {
  display: block;
  width: 340px;
  height: 160px;
  font-size: 30px;
  font-weight: bold;
  border-radius: 10px;
  border: 2px solid black;
}
.admin_dashboard__button button:hover {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  cursor: pointer;
}

.admin_dashboard__button_users {
  background-color: #479f87;
  color: white;
}
.admin_dashboard__button_reported_users {
  background-color: #9f7047;
  color: white;
}

.admin_dashboard__button_posts {
  background-color: #513e5d;
  color: #ffffff;
}

.admin_dashboard__button_reports {
  background-color: #3e5d00;
  color: #ffffff;
}
.admin_dashboard__button_citing {
  background-color: #9b0079;
  color: #ffffff;
}

/* .container {
  padding: 50px;
  display: flex;
}

.item {
  flex: 1;
}

.table {
  width: 100%;
  border-spacing: 20px;
  text-align: left;
}

.button {
  border: none;
  color: white;
  padding: 5px;
  cursor: pointer;
}

.button:first-child {
  background-color: teal;
  margin-right: 10px;
}

.button:last-child {
  background-color: crimson;
} */
