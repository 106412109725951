.container {
  margin: 0;
}
.intro {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0px;
  min-height: 100vh;
}
.intro:nth-child(2) {
  flex-direction: row-reverse;
}
.introText {
  width: 55%;
  font-size: 1rem;
  margin: 10px;
  justify-content: center;
}
h3 {
  font-weight: 620;
  text-align: justify;
}
h4 {
  font-weight: normal;
  font-size: medium;
}
.introImage {
  width: 40%;
  /* height: min-content; */
  /* padding: 10px; */
  overflow: hidden;
  height: 300px;
}
.introImage img {
  object-fit: contain;
  width: 100%;
  border-radius: 20px;
}
@media (max-width: 768px) {
  .introText {
    width: 90%;
  }
  .introImage {
    width: 90%;
  }
}
