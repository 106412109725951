.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 60vh;
}
.btn {
  background-color: red;
  font-size: 30px;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: 2px solid gray;
}
.modal {
  position: fixed;
  transition: all 1s ease-in-out;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
}
.yesBtn,
.noBtn {
  margin: 10px;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
}
.yesBtn {
  background-color: green;
}
.noBtn {
  background-color: red;
}
