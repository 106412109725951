.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
}
.heading {
  color: #2c3539;
  padding: 6px 10px;
  font-size: 20px;
  font-weight: lighter;
  border-color: #2c3539;
  border-radius: 4px;
}
.heading h2 {
  margin: 5px;
  font-weight: 600;
}
.inputContainer {
  width: 65%;
  justify-content: left;
}
.input {
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}
.clear_button {
  padding: 8px;
  font-size: 14px;
  background-color: none;
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search_button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #2c3539;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.search_button :hover {
  color: white !important;
}
.delete_button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.user_list {
  width: 64%;
}
table {
  margin-top: 0px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 10px;
}

th,
td {
  padding: 12px;
  border: 1px solid #000000;
  text-align: left;
  border-bottom: 1px solid black !important;
  padding: 5px 10px !important;
}

th {
  background-color: #f2f2f2;
  border: 1px solid black;
}
.back_button {
  background-color: #2c3539;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 100px;
  font-size: 20px;
}
.back_button:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
}
