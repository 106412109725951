.container {
  height: 90px;
  padding: 0px 20px;
  background-color: #2c3539;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
}

.item {
  display: flex;
  align-items: center;
}

.item:nth-child(2) {
  flex: 0;
}
.item:nth-child(3) {
  display: flex;
  justify-content: space-around;
  /* margin: 0; */
  flex-grow: 1;
}

.item:last-child {
  justify-content: space-around;
}

.callButton {
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
}

.texts {
  margin-left: 5px;
  width: 80px;
  color: white;
  font-weight: bold;
}

/* .text:first-child {
  font-size: 20rem;
  font-weight: bold;
  width: 80px;
} */

.text:last-child {
  font-size: 1.5rem;
  font-weight: bold;
  width: 80px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
  color: white;
}

.listItem {
  margin: 0px 40px;
  font-size: 18px;
  cursor: pointer;
}
.login {
  margin: auto;
  padding: 5px 16px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  font-size: 16px;
  margin-left: 200px;
  color: #2c3539;
  font-weight: 700;
}
.logoutBtn {
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
}
.logout {
  margin: auto;
  padding: 6px 16px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  font-size: 16px;
  color: #2c3539;
  font-weight: 700;
}

.cart {
  position: relative;
}

.counter {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #d1411e;
}
.menuBtn {
  padding: 10px;
  margin: 10px;
  background-color: #d1411e;
  display: none;
}
@media (max-width: 768px) {
  .navbar-items ul {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .navbar-items li {
    margin: 10px 0;
  }
  .menuBtn {
    display: block;
  }
  .item {
    flex-grow: 1;
  }
  #contactDetails {
    width: 1000px;
    flex-grow: 5;
  }
  .item:nth-child(2) {
    flex: 1;
  }
  .item:nth-child(3) {
    display: none;
  }
  .list {
    background-color: #d1421ee6;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 10px;
    transition: all 1s ease;
    padding: 10px;
  }
  .listItem {
    margin: 0px 10px;
    font-size: 15px;
    cursor: pointer;
  }
  .list a {
    display: block;
    margin: 10px 0px;
    padding: 0px 10px;
    transition: all 0.5s ease;
  }
  .list a:active {
    color: rgb(255, 0, 0);
  }
  #showMenu {
    position: absolute;
    top: 50%;
    right: 0%;
    display: block;
  }
  .login {
    margin-left: 20px;
  }
}
